import * as React from "react"
import Layout from "../components/Layout"

export default function IndexPage() {
	
  return (
    <Layout>
      <div>
        <h1> How to join Threedle </h1>
          <h3> Ph.D. students </h3>
            <p> We are recruiting Ph.D. students for the 2024 application cycle!
                Application deadline for the Ph.D. program in CS is December 18, 2023, see here for more information and the <a href="https://computerscience.uchicago.edu/admissions/graduate/">link to apply</a>.
            </p>
           <h3> M.Sc. students </h3>
          <p> 3DL works with pre-doctoral masters students, which is a research-oriented masters program. For more information see <a href="https://masters.cs.uchicago.edu/page/pre-doctoral-ms-computer-science">here</a>.
          </p>
          <h3> Postdoctoral Scholars </h3>
          <p> We are recruiting a Postdoctoral Scholar to work with 3DL through the <a href="https://datascience.uchicago.edu/engage/fellowships/">Data Science Institute (DSI) Postdoctoral Fellowship Program</a>.
              In addition, all the DSI fellows will be based in Chicago, and can participate in a variety of activities as a group (seminar series, workshops, bootcamp training sessions, etc) with dedicated mentoring teams.

              <li> <a href="https://uchicago.infoready4.com/#competitionDetail/1885527">Application to start in September 2023</a></li>
          </p>
      </div>
    </Layout>
  )
} 
